.table_class{
  display: block;
    /* overflow-x: scroll; */
    /* overflow-y: scroll; */
    width: 92vw;
    /* border: 2px solid black; */
    /* white-space: nowrap; */
    // overflow: hidden;

    table thead th{
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 3;
  width: 25vw;
  background: white;
} 
 table tbody th{
    position: relative;

} 
 table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 999;
} 
 table tbody td:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: rgb(208, 216, 226);
} 

 table{
  width: 100% !important;
}

}
#DataTable_dictionary{
  border-spacing: 5px;
}

/* #DataTable_dictionary th:first-child,
#DataTable_dictionary td:first-child{
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: rgb(208, 216, 226);
  
} */

 

.App {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiPaper-root::-webkit-scrollbar {
  display: none !important;
}
.iconsMinhight {
  min-height: 82px !important;
}

.btnSelectimg {
  background-color: #fff;
  border: none;
  border-radius: 100%;
  height: 40px;
  position: absolute;
  right: 19%;
  top: 45%;
  width: 40px;
}

#tableId, #DataTable_dictionary {
  border-spacing: 5px;
  border-collapse: separate;
  /* padding: 10px; */
}
tr,
td {
  border: 1px solid #eee;
  color: #3d3d3d;
}
th,
td,
tr {
  padding: 10px;
}

th {
  /* background-color: #0000a0; */
  color: white;
  position: sticky;
  top: -15px;
  font-size: 14px;
}
#search-form {
  border: 1px solid #999;
  padding: 10px;
  border-radius: 6px;
  background-color: transparent !important;
  width: 300px;
}
.custom-select {
  height: 100%;
  border: 1ps solid #999 !important;
  background-color: transparent;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
}

.imgClassNew {
  mix-blend-mode: hard-light;
  filter: brightness(70%);
}
.imgClassNew1 {
  mix-blend-mode: darken;
}

/* User Management Modal Styling */

#user-form-modal
  .MuiDialog-container
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 100%;
}

#user-form-modal
  .MuiDialog-container
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper
  .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  background: #3d3d3d;
  color: #fff;
  padding: 12px 16px;
  font-size: 20px;
}
#user-form-modal
  .MuiDialog-container
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper
  .css-ypiqx9-MuiDialogContent-root {
  padding: 16px 20px;
}

// #DataTable td{
//   span{
//     line-height: 1.7 !important;
//     overflow: hidden;
//     display: -webkit-box ;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     text-align: center !important;
//     font-size: 14px;
//   }
  
// }
// .itemsFlex{
//   span{
//     line-height: 1.7 !important;
//   overflow: hidden;
//   display: -webkit-box ;
//   -webkit-line-clamp: 1 !important;
//   -webkit-box-orient: vertical;
//   text-align: center !important;
//   font-size: 14px;
//   }
// } 
// .tableTd{
//   display: flex !important;
//   gap: 10px;
// }

.subtitle{
  font-weight: 500 !important;
  font-size: 14px;
  text-align: left;
  justify-content: flex-start;
  padding-bottom: 10px;
}
.Headertitle{
  font-size: 14px;
  text-align: left;
  font-weight: 700;
  justify-content: flex-start;
  min-height: 50px;
}

iframe {
  width: 100%;
  height: 100vh;
  border: none;
}